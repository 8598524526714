<template lang="pug">

  div(
    height="100%"
    width="100%"
  ).overflow-y-auto.overflow-hidden.px-md-10
        logout(v-model="showLogout", @click="showLogout = true")
        v-app(style="background: #f3f3f3")
          v-app-bar(
          app,
          flat,
          height="55"
          hide-on-scroll
          outlined
          scroll-threshold="30"
          ).px-4.px-md-1.pl-2
            v-row(justify="start", align="center")
              v-col.mt-lg-1.mb-lg-1.mt-2(md="3", sm="4", cols="10")
                .d-inline.ml-md-n2.ml-n4
                  v-icon.mb-2.rounded-lg.head-text.pl-1(
                    @click.stop="drawer = !drawer",
                  ) mdi-menu
                .d-inline.pt-3.pl-1.pr-n4.pl-md-2
                  h4.mt-3.font-weight-bold.grey--text.d-inline e
                  h4.font-weight-bold.head-text.d-inline.text Book
                  h4.ml-1.font-weight-light.d-inline.text Hub

              v-col.d-flex.justify-content-end.mt-2.ml-auto(md="2", sm="1", cols="1")
                v-menu(transition="slide-x-transition", offset-y, bottom, right)
                  template(v-slot:activator="{ on, attrs }")
                    v-btn.mb-3.mr-n6.mr-sm-n5(
                      v-bind="attrs",
                      v-on="on",
                      depressed,
                      v-if="$vuetify.breakpoint.smAndDown",
                      fab,
                      color="transparent",
                      x-small
                    )
                      
                      v-avatar.text-center(size="28" v-if="!$store.state.userToken.picture")
                        v-icon(large color="grey") mdi-account-circle
                      v-avatar.justify-content-end(size="28" v-else)
                        img(:src="$store.state.userToken.picture")
                    v-btn.mb-3.ml-auto.avatar-text(
                      v-bind="attrs",
                      v-on="on",
                      dark,
                      depressed,
                      rounded
                      v-else
                    ).mr-1
                      v-avatar.text-center(size="24" v-if="!$store.state.userToken.picture").mr-2
                        v-icon( color="grey") mdi-account-circle
                      v-avatar.justify-content-center.mr-2(size="24" v-else)
                        img(:src="$store.state.userToken.picture")
                      span User

                  //- Account Dropdown
                  v-list
                    v-list-item(
                      dense,
                      :to="{ path: '/user/profile' }",
                      replace
                    ).no-design
                      //- Profile
                      v-list-item-icon
                        v-icon.ml-1 mdi-account
                      v-list-item-title.no-design.ml-n4(dense) Profile

                    v-list-item(
                      dense,
                      @click.stop="showLogout = true"
                    )
                      //- Survey Form Logout
                      v-list-item-icon
                        v-icon.ml-1 mdi-logout
                      v-list-item-title.ml-n4(dense) Logout

          //- The content
          router-view
          v-footer.pb-3.mt-5.align-center.ml-9(color="transparent", padless) 
            v-card.text-center.align-center(flat, tile, color="transparent", width="1200")
              v-divider
              v-btn.mx-4(v-for="icon in icons", :key="icon.name", icon dark)
                <a :href="icon.url" :target="icon.target"><v-icon size="24px">{{ icon.name }}</v-icon></a>
              h6.mt-2.text-muted CS Libraries eBook Hub — {{ new Date().getFullYear() }}
              h6.mt-2.text-muted Funded by the <em> College of Science, University of the Philippines Diliman </em>           
        div
          //- navigation drawer
          sidebar(v-model="drawer")

</template>

<script>
import Logout from "./Logout.vue";
import sidebar from "./Sidebar.vue";
import $ from "jquery";

export default {
  components: { Logout, sidebar },
  data() {
    return {
      drawer: false,
      dialog: false,
      showLogout: false,
      users: [{ title: "Profile", route: "/user/profile" }],
      value: ["title", "author", "year"],
      icons: [
        {
          name: "mdi-facebook",
          url: "https://www.facebook.com/UPCSLibrary/",
          target: "_blank",
        },
        {
          name: "mdi-twitter",
          url: "https://twitter.com/updcslib",
          target: "_blank",
        },
        {
          name: "mdi-web",
          url: "http://www.cslib.upd.edu.ph/index.php",
          target: "_blank",
        },
      ],
    };
  },
  mounted() {
    $(function() {
      $(document).scroll(function() {
        var $nav = $(".navbar-fixed-top");
        $nav.toggleClass("scrolled", $(this).scrollTop() > $nav.height());
      });
    });
  },
};
</script>

<style scoped>
.bg-primary {
  background-color: #f3f3f3 !important;
}

.navbar-fixed-top.scrolled {
  background-color: rgba(2, 0, 115, 0.8) !important;
  transition: background-color 200ms linear;
}

.navbar-fixed-top.scrolled .text {
  color: white;
}

.navbar-fixed-top.scrolled .head-text {
  color: white;
}

.navbar-fixed-top.scrolled .avatar-text {
  background-color: #c2b9af !important;
  color: #010073;
}

.avatar-text {
  background: rgba(53, 51, 143, 0.9) !important;
  color: white;
}

.head-text {
  color: #010073;
}

.v-list-item.no-line {
  text-decoration: none !important;
}

.no-design {
  text-decoration: none !important;
  color: black;
}

.no-design :hover {
  text-decoration: none !important;
}
</style>
